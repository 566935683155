import Accordion from 'accordion-js';
import animateScrollTo from 'animated-scroll-to';

export default class SbpAccordion {

  constructor(selector) {
    this.accordionWrappers = document.querySelectorAll(selector);
    if (!this.accordionWrappers.length) {
      return;
    }

    for (let i = 0; i < this.accordionWrappers.length; ++i) {
      if (this.accordionWrappers[i].dataset.accordionInitialized) { return }
      this.accordionWrappers[i].dataset.accordionInitialized = true;

      const options = {
        duration: 500,
      }

      if (window.location.hash) {
        const targetAccordion = document.querySelector('.ac[data-accordion="' + window.location.hash.replace('#', '') + '"]')
        if (targetAccordion) {
          options.openOnInit = [parseInt(targetAccordion.dataset.index)];
          setTimeout(() => {
            animateScrollTo(targetAccordion.getBoundingClientRect().top - 100)
          }, 1000);
        }
      }

      new Accordion('#' + this.accordionWrappers[i].id, options);
    }

  }

}
